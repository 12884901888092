import { Link, Navigate } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import { useAuth } from "./UseAuth";
import './Login.css';

export function Signup() {
    const auth = useAuth();
    const usernameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const [badInputDescription, setBadInputDescription] = useState("");

    const signup = async () => {
        await auth.signup(usernameRef.current.value, emailRef.current.value, passwordRef.current.value);
    };

    const onSignup = async (event) => {
        event.preventDefault();
        if (!usernameRef.current.value) {
            setBadInputDescription('Username is empty');
            return;
        }
        if (!emailRef.current.value) {
            setBadInputDescription('Email is empty');
            return;
        }
        validatePassword();
        await signup();
    };

    const handleKeyDown = async (event) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            await signup();
        } else {
            auth.setErrMsg(null);
        }
    };

    const validatePassword = () => {
        if (!passwordRef.current.value) {
            setBadInputDescription('Password is empty');
            return;
        }
        if (passwordRef.current.value.length < 8) {
            setBadInputDescription('Password should have a minimum of 8 characters');
            return;
        }
        if (!passwordRef.current.value.match(new RegExp('[A-Z]'))) {
            setBadInputDescription('Password should contain at least one uppercase character (A-Z)');
            return;
        }
        if (!passwordRef.current.value.match(new RegExp('[a-z]'))) {
            setBadInputDescription('Password should contain at least one lowercase character (a-z)');
            return;
        }
        if (!passwordRef.current.value.match(new RegExp('[0-9]'))) {
            setBadInputDescription('Password should contain at least one number');
            return;
        }
        setBadInputDescription('');
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    if (auth.isUserNotConfirmed) {
        return <Navigate to="/confirmsignup" />
    }

    return auth.user ? <Navigate to="/" /> : (
        <div className="login-wrapper">
            <div className="login-form-wrapper">
                <div className="login-element-wrapper">
                    <input type="text" placeholder="username" style={{ 'textTransform': 'lowercase' }} ref={usernameRef} className="login-input"></input>
                </div>
                <div className="login-element-wrapper">
                    <input type="email" placeholder="email" style={{ 'textTransform': 'lowercase' }} ref={emailRef} className="login-input"></input>
                </div>
                <div className="login-element-wrapper">
                    <input type="password" placeholder="password" onChange={validatePassword} ref={passwordRef} className="login-input"></input>
                </div>
                <div className="login-element-wrapper">
                    <button type="submit" onClick={onSignup} className="login-button">Create Account</button>
                </div>
                <div className="login-element-wrapper">
                    <Link to="/login" className="login-other-option">Already have an account? Click here to login</Link>
                </div>
            </div>
            <div className="login-bad-input">{badInputDescription || auth.errMsg}</div>
        </div>
    )
}
