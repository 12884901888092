import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import { ProvideAuth } from "./UseAuth";
import { Login } from "./Login";
import { Signup } from "./Signup";
import { ConfirmSignup } from "./ConfirmSignup";
import { Confirmed } from "./Confirmed";
import { PrivateRoute } from "./PrivateRoute";
import { Tasks } from "./Tasks";
import './App.css';

export function App() {
    return (
        <ProvideAuth>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/confirmsignup" element={<ConfirmSignup />} />
                    <Route path="/confirmed" element={<Confirmed />} />
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route exact path='/' element={<PrivateRoute />}>
                        <Route exact path='' element={<Tasks />} />
                    </Route>
                </Routes>
            </Router>
        </ProvideAuth>
    );
}
