import { Link } from "react-router-dom";
import './Login.css';

export function Confirmed() {
    return (
        <div className="login-wrapper">
            <div className="login-form-wrapper">
                <div className="login-element-wrapper">
                    Verified!
                </div>
                <div className="login-element-wrapper">
                    <Link to="/login" className="login-other-option">Click here to login</Link>
                </div>
            </div>
        </div>
    )
}
