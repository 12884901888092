import { Link, Navigate } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useAuth } from "./UseAuth";
import './Login.css';

export function ConfirmSignup() {
    const auth = useAuth();
    const usernameRef = useRef();
    const codeRef = useRef();

    const confirmSignup = async () => {
        await auth.confirmSignup(usernameRef.current.value, codeRef.current.value);
    };

    const onConfirmSignup = async (event) => {
        event.preventDefault();
        if (!usernameRef.current.value) {
            console.log('Username is empty');
            return;
        }
        if (!codeRef.current.value) {
            console.log('Code is empty');
            return;
        }
        await confirmSignup();
    };

    const handleKeyDown = async (event) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            await confirmSignup();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });


    if (!auth.isUserNotConfirmed) {
        return <Navigate to="/confirmed" />
    }
    return (
        <div className="login-wrapper">
            <div className="login-form-wrapper">
                <div className="login-element-wrapper">
                    <input type="text" placeholder="username" style={{ 'textTransform': 'lowercase' }} ref={usernameRef} className="login-input"></input>
                </div>
                <div className="login-element-wrapper">
                    <input type="text" placeholder="code" ref={codeRef} className="login-input"></input>
                </div>
                <div className="login-element-wrapper">
                    <button type="submit" onClick={onConfirmSignup} className="login-button">Confirm Account</button>
                </div>
                <div className="login-element-wrapper">
                    <Link to="/login" className="login-other-option">Already verified? Click here to login</Link>
                </div>
            </div>
        </div>
    )
}
