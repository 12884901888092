import { useState, useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from "./UseAuth";
import { authFetch } from "./AuthFetch";
import './Tasks.css';
import debounce from 'lodash.debounce';


export function Tasks() {
    const auth = useAuth();
    const [isLoaded, setIsLoaded] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [selectedTaskId, setSelectedTaskId] = useState(-1);
    const taskListRef = useRef(null);

    const signOut = useCallback(async () => {
        await auth.signout();
    });

    const createTask = useCallback(() => {
        if (!isLoaded) {
            return;
        }
        const id = uuidv4();
        const origTasks = [...tasks];
        for (let i = 0; i < origTasks.length; i++) {
            const task = origTasks[i];
            task.isSelected = false;
            task.isSelectedToEdit = false;
        }
        const newTasks = [
            { id, description: '', isSelected: true, isSelectedToEdit: true },
            ...origTasks
        ];
        setTasks(newTasks);
        setSelectedTaskId(id);
    });

    const handleTaskClick = useCallback((event) => {
        event.preventDefault();
        if (!isLoaded) {
            return;
        }
        setSelectedTaskId(event.target.id);
        const newTasks = [...tasks];
        for (let i = 0; i < newTasks.length; i++) {
            const task = newTasks[i];
            task.isSelectedToEdit = task.isSelected;
            task.isSelected = task.id === event.target.id;
        }
        setTasks(newTasks);
    });

    const updateTasks = useCallback(debounce(async (tasks) => {
        if (!tasks.length) {
            return;
        }
        try {
            await authFetch('https://tasksapi.shayashk.com/tasks', await auth.getJwtToken(), {
                method: "POST",
                mode: 'cors',
                body: JSON.stringify({ tasks }),
            });
        } catch (e) {
            console.log(e);
        }
    }, 500), []);

    useEffect(() => {
        updateTasks(tasks);
    }, [tasks]);

    useEffect(() => {
        if (taskListRef.current) {
            taskListRef.current.scrollTop = 0;
        }
    }, []);

    const handleTaskChange = async (event) => {
        if (!isLoaded) {
            return;
        }
        const newTasks = [...tasks];
        newTasks.forEach(task => {
            if ("" + task.id === "" + event.target.id) {
                task.description = event.target.value;
            }
        });
        setTasks(newTasks);
    };

    const handleFocusOnTask = useCallback((event) => {
        setSelectedTaskId(event.target.id);
    });

    const handleKeyPress = useCallback((event) => {
        if (event.ctrlKey || event.altKey) {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
                createTask();
            } else if (event.code === 'ArrowUp') {
                raiseSelectedTask();
            } else if (event.code === 'ArrowDown') {
                lowerSelectedTask();
            } else if (event.code === 'KeyX') {
                deleteSelectedTaskByKeyPress();
            }
        } else if (event.code === 'Backspace') {
            deleteSelectedTaskByKeyPress(true);
        } else if (event.code === 'Tab') {
            event.preventDefault();
            event.shiftKey ? selectPreviousTask() : selectNextTask();
        } else if (event.code === 'ArrowDown') {
            selectNextTask();
        } else if (event.code === 'ArrowUp') {
            selectPreviousTask();
        } else if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            selectCurrentTaskToEdit();
        }
    });

    const raiseSelectedTask = useCallback(() => {
        if (!isLoaded) {
            return;
        }
        if (tasks.length < 2) {
            return;
        }
        const newTasks = [...tasks];
        for (let i = 1; i < newTasks.length; i++) {
            const task = newTasks[i];
            if (task.id === selectedTaskId) {
                const highTask = newTasks[i - 1];
                newTasks[i - 1] = newTasks[i];
                newTasks[i] = highTask;
                break;
            }
        }
        setTasks(newTasks);
    });

    const lowerSelectedTask = useCallback(() => {
        if (!isLoaded) {
            return;
        }
        if (tasks.length < 2) {
            return;
        }
        const newTasks = [...tasks];
        for (let i = 0; i < newTasks.length - 1; i++) {
            const task = newTasks[i];
            if (task.id === selectedTaskId) {
                const lowTask = newTasks[i + 1];
                newTasks[i + 1] = newTasks[i];
                newTasks[i] = lowTask;
                break;
            }
        }
        setTasks(newTasks);
    });

    const selectPreviousTask = useCallback(() => {
        if (!isLoaded) {
            return;
        }
        const newTasks = [...tasks];
        for (let i = 1; i < newTasks.length; i++) {
            const task = newTasks[i];
            if (task.isSelected) {
                task.isSelected = false;
                newTasks[i - 1].isSelected = true;
                setSelectedTaskId(newTasks[i - 1].id);
                break;
            }
        }
        setTasks(newTasks);
    });

    const selectNextTask = useCallback(() => {
        if (!isLoaded) {
            return;
        }
        const newTasks = [...tasks];
        for (let i = 0; i < newTasks.length - 1; i++) {
            const task = newTasks[i];
            if (task.isSelected) {
                task.isSelected = false;
                newTasks[i + 1].isSelected = true;
                setSelectedTaskId(newTasks[i + 1].id);
                break;
            }
        }
        setTasks(newTasks);
    });

    const selectCurrentTaskToEdit = useCallback(() => {
        if (!isLoaded) {
            return;
        }
        const newTasks = [...tasks];
        for (let i = 0; i < newTasks.length; i++) {
            const task = newTasks[i];
            if (task.isSelected) {
                task.isSelectedToEdit = true;
                break;
            }
        }
        setTasks(newTasks);
    });

    const deleteSelectedTaskByKeyPress = useCallback((deleteOnlyIfEmpty = false) => {
        if (!isLoaded) {
            return;
        }
        const newTasks = [...tasks];
        let deletedTaskPosition = 0;
        for (let i = 0; i < newTasks.length; i++) {
            const task = newTasks[i];
            if (task.id === selectedTaskId) {
                if (deleteOnlyIfEmpty && task.description.length > 0) {
                    return;
                }
                newTasks.splice(i, 1);
                deletedTaskPosition = i;
                break;
            }
        }
        if (newTasks.length) {
            setSelectedTaskId(newTasks[deletedTaskPosition].id);
            newTasks[deletedTaskPosition].isSelected = true;
        }
        setTasks(newTasks);
    });

    const deleteSelectedTask = useCallback(() => {
        if (!isLoaded) {
            return;
        }
        const newTasks = [...tasks];
        let deletedTaskPosition = 0;
        for (let i = 0; i < newTasks.length; i++) {
            const task = newTasks[i];
            if (task.id === selectedTaskId) {
                newTasks.splice(i, 1);
                break;
            }
        }
        if (newTasks.length) {
            setSelectedTaskId(newTasks[deletedTaskPosition].id);
            newTasks[deletedTaskPosition].isSelected = true;
        }
        setTasks(newTasks);
    });

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await authFetch('https://tasksapi.shayashk.com/tasks', await auth.getJwtToken());
                const { tasks = [] } = await res.json();
                setTasks(tasks);
                setIsLoaded(true);
                setTimeout(() => window.scrollTo(0, 0), 3);
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <div className="tasks-container">
                <div className="task-list-container">
                    <ul className="task-list" ref={taskListRef}>
                        {tasks.map(task => (
                            <li className="task" id={task.id} key={task.id} onClick={handleTaskClick} style={{ "backgroundColor": task.isSelected ? "#b6d4f2" : "white" }}>
                                <div>
                                    <input
                                        id={task.id}
                                        className="task-input"
                                        type="text"
                                        tabIndex="1"
                                        autoFocus={task.isSelectedToEdit}
                                        autoComplete="off"
                                        onFocus={handleFocusOnTask}
                                        defaultValue={task.description}
                                        readOnly={!task.isSelectedToEdit}
                                        onChange={handleTaskChange}
                                        dir={task.description && (task.description[0] >= 'א' && task.description[0] <= 'ת') ? 'rtl' : 'ltr'}
                                    ></input>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="task-bar">
                    <button className="task-bar-button" onClick={signOut}>Sign out</button>
                    <button className="task-bar-button" onClick={raiseSelectedTask}>↑</button>
                    <button className="task-bar-button" onClick={lowerSelectedTask}>↓</button>
                    <button className="task-bar-button" onClick={deleteSelectedTask}>X</button>
                    <button className="task-bar-button" onClick={createTask}>+</button>
                </div>
            </div>
        </>
    );
}
