import { Link, Navigate } from "react-router-dom";
import { useRef, useEffect } from "react";
import { useAuth } from "./UseAuth";
import './Login.css';


export function Login() {
    const auth = useAuth();
    const usernameRef = useRef();
    const passwordRef = useRef();

    const signin = async () => {
        await auth.signin(usernameRef.current.value, passwordRef.current.value);
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        if (!usernameRef.current.value) {
            console.log('Username is empty');
            return;
        }
        
        if (!passwordRef.current.value) {
            console.log('Password is empty');
            return;
        }
        signin();
    };

    const handleKeyDown = (event) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            signin();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    if (auth.user) {
        return <Navigate to="/" />
    }
    if (auth.isUserNotConfirmed) {
        return <Navigate to="/confirmsignup" />
    }
    return (
        <div className="login-wrapper">
            <div className="login-form-wrapper">
                <div className="login-element-wrapper">
                    <input type="text" placeholder="username" style={{ 'textTransform': 'lowercase' }} ref={usernameRef} className="login-input"></input>
                </div>
                <div className="login-element-wrapper">
                    <input type="password" placeholder="password" ref={passwordRef} className="login-input"></input>
                </div>
                <div className="login-element-wrapper">
                    <button type="submit" onClick={handleLogin} className="login-button">Login</button>
                </div>
                <div className="login-element-wrapper">
                    <Link to="/signup" className="login-other-option">New user? Click here to create an account</Link>
                </div>
            </div>
        </div>
    )
}
