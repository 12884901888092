import React, { useState, useEffect, useContext, createContext } from "react";
import { Amplify, Auth } from 'aws-amplify';

// Created user pool manually using Amazon Cognito console. Did not use Amplify CLI.
Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_RlV8Z51BU',
    userPoolWebClientId: '51pod81rt1rv2cg4b0v6k1sndl',
  }
});

const authContext = createContext();

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('USER')));
  const [isUserNotConfirmed, setIsUserNotConfirmed] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  // Wrap any Amplify methods we want to use making sure to save the user to state.
  const signup = async (username, email, password) => {
    try {
      const user = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
      });
      setIsUserNotConfirmed(true);
      return null;
    } catch (e) {
      setErrMsg(e.message);
      return null;
    }
  };

  const confirmSignup = async (username, code) => {
    try {
      const user = await Auth.confirmSignUp(username, code);
      setUser(user);
      window.localStorage.setItem('USER', JSON.stringify(user));
      setIsUserNotConfirmed(false);
      await signout();
    } catch (e) {
    }
    return null;
  };

  const signin = async (username, password) => {
    try {
      const user = await Auth.signIn(username, password)
      setUser(user);
      window.localStorage.setItem('USER', JSON.stringify(user));
      if (isUserNotConfirmed) {
        setIsUserNotConfirmed(false);
      }
      return user;
    } catch (e) {
      console.log(e.message);
      if (e.message === 'User is not confirmed.') {
        setIsUserNotConfirmed(true);
      }
      return null;
    }
  };

  const signout = async () => {
    try {
      const user = await Auth.signOut();
      setUser(user);
      window.localStorage.removeItem('USER');
    } catch (e) {
      console.log(e.message);
      return null;
    }
  };

  const getJwtToken = async () => {
    try {
      const currentSession = await Auth.currentSession();
      return currentSession?.accessToken?.jwtToken;
    } catch (e) {
      console.log('failed to get currentSession');
      return null;
    }
  };

  // Return the user object and auth methods
  return {
    user,
    isUserNotConfirmed,
    errMsg,
    setErrMsg,
    signin,
    signup,
    confirmSignup,
    signout,
    getJwtToken,
  };
}