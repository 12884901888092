export const authFetch = async (url, token, options) => {
    const optionsWithAuth = {
        ...options,
        headers: new Headers({
            ...options?.headers,
            Authorization: `Bearer ${token}`,
        })
    };
    return fetch(url, optionsWithAuth);
};
